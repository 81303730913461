<template>
  <b-card>
    <b-row class="justify-content-between">
      <b-col class="pr-md-32 pr-md-120">
        <h4>Sticky columns</h4>

        <p class="hp-p1-body">
          Columns can be made sticky, where they stick to the left of the table
          when the table has a horizontal scrollbar. To make a column a sticky
          column, set the <code>stickyColumn</code> prop in the
          <a href="https://bootstrap-vue.org/docs/components/table#field-definition-reference" target="_blank">field's
            header definition</a>. Sticky columns will only work when the table has either the
          <code>sticky-header</code> prop set and/or the responsive prop is set.
        </p>
      </b-col>

      <b-col class="hp-flex-none w-auto">
        <b-button @click="codeClick()" variant="text" class="btn-icon-only show-code-btn">
          <i class="ri-code-s-slash-line hp-text-color-black-80 hp-text-color-dark-30 lh-1" style="font-size: 16px"></i>
        </b-button>
      </b-col>
    </b-row>

    <b-row>
      <div class="col-12 mt-16">
        <div class="mb-2">
          <b-form-checkbox v-model="stickyHeader" inline>
            Sticky header
          </b-form-checkbox>

          <b-form-checkbox v-model="noCollapse" inline>
            No border collapse 
          </b-form-checkbox>
        </div>

        <b-table :sticky-header="stickyHeader" :no-border-collapse="noCollapse" responsive :items="items"
          :fields="fields">
          <template #head(id)>
            <div class="text-nowrap">Row ID</div>
          </template>

          <template #head()="scope">
            <div class="text-nowrap">Heading {{ scope.label }}</div>
          </template>
        </b-table>
      </div>

      <div v-if="codeActive" class="col-12 mt-24 hljs-container" :class="{ active: codeActiveClass }">
        <pre v-highlightjs>
          <code class="hljs html">
            {{ codeText }}
          </code>
        </pre>
      </div>
    </b-row>
  </b-card>
</template>

<script>
import { BRow, BCol, BCard, BButton, BTable, BFormCheckbox } from "bootstrap-vue";

import code from "./code";

export default {
  data() {
    return {
      stickyHeader: true,
      noCollapse: false,
      fields: [
        { key: 'id', stickyColumn: true, isRowHeader: true, variant: 'primary', class: 'hp-bg-dark-primary' },
        'a',
        'b',
        { key: 'c', stickyColumn: true, variant: 'info', class: 'hp-bg-dark-info' },
        'd',
        'e',
        'f',
        'g',
        'h',
        'i',
        'j',
        'k',
        'l',
        'm',
        'n',
        'o',
        'p',
        'r',
        's',
      ],
      items: [
        { id: 1, a: 0, b: 1, c: 2, d: 3, e: 4, f: 5, g: 6, h: 7, i: 8, j: 9, k: 10, l: 11, m: 12, n: 13, o: 14, p: 15, r: 16, s: 17, },
        { id: 2, a: 0, b: 1, c: 2, d: 3, e: 4, f: 5, g: 6, h: 7, i: 8, j: 9, k: 10, l: 11, m: 12, n: 13, o: 14, p: 15, r: 16, s: 17, },
        { id: 3, a: 0, b: 1, c: 2, d: 3, e: 4, f: 5, g: 6, h: 7, i: 8, j: 9, k: 10, l: 11, m: 12, n: 13, o: 14, p: 15, r: 16, s: 17, },
        { id: 4, a: 0, b: 1, c: 2, d: 3, e: 4, f: 5, g: 6, h: 7, i: 8, j: 9, k: 10, l: 11, m: 12, n: 13, o: 14, p: 15, r: 16, s: 17, },
        { id: 5, a: 0, b: 1, c: 2, d: 3, e: 4, f: 5, g: 6, h: 7, i: 8, j: 9, k: 10, l: 11, m: 12, n: 13, o: 14, p: 15, r: 16, s: 17, },
        { id: 6, a: 0, b: 1, c: 2, d: 3, e: 4, f: 5, g: 6, h: 7, i: 8, j: 9, k: 10, l: 11, m: 12, n: 13, o: 14, p: 15, r: 16, s: 17, },
        { id: 7, a: 0, b: 1, c: 2, d: 3, e: 4, f: 5, g: 6, h: 7, i: 8, j: 9, k: 10, l: 11, m: 12, n: 13, o: 14, p: 15, r: 16, s: 17, },
        { id: 8, a: 0, b: 1, c: 2, d: 3, e: 4, f: 5, g: 6, h: 7, i: 8, j: 9, k: 10, l: 11, m: 12, n: 13, o: 14, p: 15, r: 16, s: 17, },
        { id: 9, a: 0, b: 1, c: 2, d: 3, e: 4, f: 5, g: 6, h: 7, i: 8, j: 9, k: 10, l: 11, m: 12, n: 13, o: 14, p: 15, r: 16, s: 17, },
        { id: 10, a: 0, b: 1, c: 2, d: 3, e: 4, f: 5, g: 6, h: 7, i: 8, j: 9, k: 10, l: 11, m: 12, n: 13, o: 14, p: 15, r: 16, s: 17, },
        { id: 11, a: 0, b: 1, c: 2, d: 3, e: 4, f: 5, g: 6, h: 7, i: 8, j: 9, k: 10, l: 11, m: 12, n: 13, o: 14, p: 15, r: 16, s: 17, },
        { id: 12, a: 0, b: 1, c: 2, d: 3, e: 4, f: 5, g: 6, h: 7, i: 8, j: 9, k: 10, l: 11, m: 12, n: 13, o: 14, p: 15, r: 16, s: 17, },
        { id: 13, a: 0, b: 1, c: 2, d: 3, e: 4, f: 5, g: 6, h: 7, i: 8, j: 9, k: 10, l: 11, m: 12, n: 13, o: 14, p: 15, r: 16, s: 17, },
        { id: 14, a: 0, b: 1, c: 2, d: 3, e: 4, f: 5, g: 6, h: 7, i: 8, j: 9, k: 10, l: 11, m: 12, n: 13, o: 14, p: 15, r: 16, s: 17, },
        { id: 15, a: 0, b: 1, c: 2, d: 3, e: 4, f: 5, g: 6, h: 7, i: 8, j: 9, k: 10, l: 11, m: 12, n: 13, o: 14, p: 15, r: 16, s: 17, },
        { id: 16, a: 0, b: 1, c: 2, d: 3, e: 4, f: 5, g: 6, h: 7, i: 8, j: 9, k: 10, l: 11, m: 12, n: 13, o: 14, p: 15, r: 16, s: 17, },
        { id: 17, a: 0, b: 1, c: 2, d: 3, e: 4, f: 5, g: 6, h: 7, i: 8, j: 9, k: 10, l: 11, m: 12, n: 13, o: 14, p: 15, r: 16, s: 17, },
        { id: 18, a: 0, b: 1, c: 2, d: 3, e: 4, f: 5, g: 6, h: 7, i: 8, j: 9, k: 10, l: 11, m: 12, n: 13, o: 14, p: 15, r: 16, s: 17, },
        { id: 19, a: 0, b: 1, c: 2, d: 3, e: 4, f: 5, g: 6, h: 7, i: 8, j: 9, k: 10, l: 11, m: 12, n: 13, o: 14, p: 15, r: 16, s: 17, },
        { id: 20, a: 0, b: 1, c: 2, d: 3, e: 4, f: 5, g: 6, h: 7, i: 8, j: 9, k: 10, l: 11, m: 12, n: 13, o: 14, p: 15, r: 16, s: 17, },
      ],
      codeText: code.stickyColumns,
      codeActive: false,
      codeActiveClass: false,
    };
  },
  components: {
    BRow,
    BCol,
    BCard,
    BButton,
    BTable,
    BFormCheckbox,
  },
  methods: {
    codeClick() {
      this.codeActive = !this.codeActive;

      setTimeout(() => {
        this.codeActiveClass = !this.codeActiveClass;
      }, 100);
    },
  },
};
</script>
